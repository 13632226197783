import "./styles/app.css";
import React, { useState, useEffect, useCallback, Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";
import Loading from "./components/loading";
axios.defaults.headers.common["x-api-key"] = "@qazQAZ1WsX!S12";

// بارگذاری تنبل کامپوننت‌ها
const Home = lazy(() => import("./components/home"));
const NotFound = lazy(() => import("./components/not_found"));
const ManagementPanel = lazy(() => import("./components/managementPanel"));
const UserPanel = lazy(() => import("./components/userPanel"));
const Loginm = lazy(() => import("./components/loginm"));
const Live = lazy(() => import("./components/live"));
const AboutUs = lazy(() => import("./components/aboutus"));
const Contactus = lazy(() => import("./components/contactus"));

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState(null);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("authData"));
    if (authData && authData.username && authData.role) {
      setIsAuthenticated(true);
      setRole(authData.role);
      setUsername(authData.username);
    } else {
      setIsAuthenticated(false);
      setRole(null);
      setUsername(null);
    }
  }, []);

  // بارگذاری داده‌های ادمین‌ها
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await axios.get("http://localhost:8081/is_live");
        setAdmins(response.data);
      } catch (error) {
        console.error("Error fetching admins:", error);
      }
    };

    fetchAdmins();
  }, []);

  const handleLogin = useCallback((username, role) => {
    setIsAuthenticated(true);
    setRole(role);
    setUsername(username);
    localStorage.setItem("authData", JSON.stringify({ username, role }));
  }, []);

  const handleLogout = useCallback(() => {
    setIsAuthenticated(false);
    setRole(null);
    setUsername(null);
    localStorage.removeItem("authData");
  }, []);

  return (
    <Suspense
      fallback={
        <div>
          <Loading />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/loginm" element={<Loginm onLogin={handleLogin} />} />
        <Route
          path="/managementpanel/:username"
          element={
            isAuthenticated && role === "manager" ? (
              <ManagementPanel username={username} onLogout={handleLogout} />
            ) : (
              <Navigate to="/loginm" />
            )
          }
        />
        <Route
          path="/userpanel/:username"
          element={
            isAuthenticated && role === "admin" ? (
              <UserPanel username={username} />
            ) : (
              <Navigate to="/loginm" />
            )
          }
        />
        <Route path="/live/:username" element={<Live admins={admins} />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/not_found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not_found" />} />
      </Routes>
    </Suspense>
  );
};

export default App;
