import React from 'react';
import Lottie from 'react-lottie-player';
import lottieL from "../assets/images/Animation - 1720524594935.json";

const Loading = () => {

  return (
    <div className="loading-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <Lottie
        loop
        animationData={lottieL}
        style={{ width: "300px" }}
        play
      />
      <p style={{ fontFamily: "VAZIRB", color: "white", marginTop: "20px" }}>
        از شکیبایی شما متشکریم.
      </p>
    </div>
  );
};

export default Loading;
